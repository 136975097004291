:root {
  --blackColor: var(--grey-dark);
  --grey-light: #cccccc;
  font-family: "Inter";
}
.card {
  border-radius: 8px !important;
  background-color: var(--white) !important;
  margin-top: 20px;
  border: none !important;
  padding-top: 1.5rem;
  padding-bottom: 1rem; 
  padding-left: 32px;
  font-family: "Inter";
}

.main-cards-container .card {
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04) !important;
}
.card .flex-wrap {
  flex-wrap: nowrap !important;
}
.main-cards-container {
  width: 80%;
}
.card-rental-row{
  display: flex !important;
  flex-direction: row;
}
.net-rental-container{
  margin-bottom: 0px;
}
@media(max-width:1460px){
  .card-rental-row{
    flex-direction: column;
  }
  .card-rental-row .card-col{
    margin-left: 0px !important;
  }
  .net-rental-container{
    margin-bottom: 28px;
  }
}
@media only screen and (max-width: 1024px) {
  .card .ml-4 {
    margin: 0 !important;
  }
  .card .flex-wrap {
    flex-wrap: wrap !important;
  }
  .card .ml-5.pl-5 {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .card-gredient .ml-5 {
    margin-left: 0 !important;
  }
}
.card-gredient {
  background: linear-gradient(
    20deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 100%
  ) !important;
  border-radius: 8px;
  min-height: 160px;
  margin-top: 20px;
}
.card-gredient .column-1 {
  padding-top: 1.5rem;
  padding-left: 32px !important;
  padding-bottom: 1.5rem;
}
.card-gredient .card-title,
.card-gredient .input-label {
  color: var(--text-color) !important;
  font-family: "Inter";
  padding: 0px !important;
}
.card-gredient .input-field {
  background-color: transparent !important;
  color: var(--text-color);
  width: 320px !important;
  margin-bottom: 10px;
}
.p-dropdown {
  height: 36px !important;
  line-height: 20px;
  font-size: 12px !important;
}
.card-gredient .input-label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.card-title {
  font-size: 24px !important;
  font-weight: 500;
  color: var(--blackColor);
}
.input-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--blackColor) !important;
}
.input-field-text {
  font-size: 12px;
}
.input-field {
  height: 36px !important;
  width: 260px !important;
}
.slider-field {
  width: 204px;
  background-color: var(--primary-blue) !important;
}
.p-slider-horizontal{
  background-color: var(--primary-blue) !important;
}

.dropDown-field-container {
  height: 36px;
}

.p-slider {
  height: 2px !important;
}
.p-slider-handle {
  background-color: var(--white) !important;
  border: none !important;
  background: url("../../assets/images/SliderCircle.png") !important;
  background-position: center !important;
  background-size: contain !important;
}
.dependant-input {
  width: 53px !important;
  margin-top: -18px;
  text-align: center;
  border-radius: 4px;
  border: solid 1px #ccc;
  color: var(--grey-dark) !important;
}

.readonly-input-field {
  position: relative !important;
  top: -20px !important;
  height: 36px;
  max-width: 123px !important;
  color: var(--grey-dark) !important;
}
.readonly-input-field.w-83 {
  width: 83px !important;
  text-align: center !important;
}
.readonly-input-field.w-89 {
  width: 83px !important;
  text-align: center !important;
}
.readonly-input-field.w-67 {
  width: 67px !important;
  text-align: center !important;
}
.readonly-input-field.w-90 {
  width: 98px !important;
  text-align: center !important;
}
.custom-input-field {
  margin-top: -20px;
  color: var(--grey-dark) !important;
  text-align: center !important;
}
.custom-input-field .w-82 {
  width: 82px !important;
}
.custom-input-field .w-86 {
  width: 86px !important;
}
.custom-input-field .w-101 {
  width: 101px !important;
}
.custom-input-field .w-90 {
  width: 90px !important;
}
.custom-input-field .w-69 {
  width: 69px !important;
}
.p-inputnumber-input {
  width: 320px !important;
  height: 36px !important;
  font-size: 12px !important;
}
.creditCardTable .p-inputnumber-input:focus,
.creditCardTable .credit-card-name:focus,
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: none;
  outline: none;
}
.creditCardTable .p-dropdown {
  width: 100% !important ;
}
.creditCardTable .p-dropdown-trigger {
  width: 20px;
}

.credit-card-name {
  width: 200px !important;
  height: 36px !important;
  font-size: 12px !important;
}
.creditCardTable .credit-input-field,
.creditCardTable1 .credit-input-field {
  width: 100px !important;
}
.p-inputtext::-webkit-outer-spin-button,
.p-inputtext::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.p-checkbox-box {
  width: 18px !important;
  height: 18px !important;
  border: solid 1px var(--grey-dark) !important;
  border-radius: 5px !important;
}
.p-checkbox-box {
  box-shadow: none !important;
}
.p-checkbox .p-highlight {
  background-color: transparent !important;
  border: 1px solid var(--gredient-blue-1) !important;
}
.p-checkbox-icon {
  color: var(--gredient-blue-1) !important;
}
.p-checkbox-icon::before {
  font-size: 10px;
  font-weight: bold;
}
.p-dropdown-label {
  font-size: 13px !important;
  color: #323232 !important;
}
.p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down::before {
  font-size: 13px !important;
}
.checkbox-label {
  font-size: 13px;
  position: relative;
  top: -1.5px;
}

.p-inputtext[type="number"] {
  -moz-appearance: textfield;
}
.card-col {
  position: relative;
}
.expense-input-container{
 margin-top: 0rem !important;
  flex-wrap: wrap;
  max-width: 900px !important;
} 
.income-gradient1{
  display: flex;
  flex-direction: row;
}
@media(max-width:760px){
  .income-gradient1{
    flex-direction: column;
  }
  .income-gradient1 div:nth-child(1){
    margin-bottom: 24px;
  }
}
@media(max-width:1200px){
  .main-cards-container{
    width: 100%;
  }
}
@media(max-width:1480px){
  .expense-input-container{
    max-width: 100% !important;
  }
 
}
.sub-title {
  font-size: 20px;
  font-weight: 500;
}
.Gradient-Box.w-80 {
  max-width: 80% !important;
}
.Gradient-Box {
  padding: 25px 20px;
  margin: 0 !important;
  width: 100%;
  background-image: linear-gradient(
    10deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 123%
  );
  border-radius: 0 0 7px 7px;
  color: white;
}

.strong-text {
  font-size: 16px;
  font-weight: bold;
}
.loginBtn {
  background: linear-gradient(
    90deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 100%
  );
  width: 136px;
  height: 47px;
  border: none !important;
  border-radius: 4px !important;
  margin-top: 15px;
}
.Line-18 {
  height: 1px;
 
  background-color: rgba(255, 255, 255, 0.19);
}
.net-borrowing-power {
  border-radius: 8px;
  height: 37px;
}
.net-borrowing-power > div {
  height: 37px !important;
  padding: 12px 8px;
  padding-top: 5px;
}
.net-borrowing-power p {
  color: var(--solid-colors) !important;
  font-size: 16px;
  font-weight: bold;
}
.showInfoBox {
  display: block;
}
.hideInfoBox {
  display: none;
}

.list {
  counter-reset: foo;
  display: table;
}

.list > li {
  counter-increment: foo;
  display: table-row;
}

.list > li::before {
  content: "•";
  font-size: 18px;
  display: table-cell;
  text-align: right;
  margin-left: -30px;
  margin-top: -4px;
  padding-right: 10px;
}



