.header {
  cursor: pointer;
  background-color: var(--white) !important;
  color: var(--grey-dark) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none !important;
}
.Body {
  border: 1px solid #ccc;
  background-color: var(--white);
  padding: 7px 15px;
}
.collapseIcon{
  filter: invert();
  user-select: none;
}
.icon {
  font-size: 14px;
}
