.vectorInfo{
    background: linear-gradient(225deg, var(--gradient-blue-3) 0%, var(--gradient-blue-4) 0.01%, var(--gradient-blue-5) 100%);
   
}
.formContainer{
    min-height: 100vh;
    width: 65%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
/* background-color: orange; */
}
.greeting{
    font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--heading-color);
 
}
.vectorImgContainer{
    padding-top: 241px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RemovePadding{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px !important;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}
.logoContainer{
    width: 100%;
   display: flex;
   justify-content: center;
   

}
.title{
   margin-bottom: 26px;
    color:#323232;
    font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  
}
.vectorImg{
    width: 624.4px;
  height: 341.2px
}
.link{
    font-size: 14px;
    color: var(--heading-color);
    cursor: pointer;
    margin-top: 10px;
}