.table-containers {
  overflow-x: scroll !important;
  padding: 5px;
  max-width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.txtRed{
  color:red !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
.spendingBox{
  background-color: white;
  padding: 32px;
  box-shadow: rgb(0 0 0 / 2%);
  border-radius: 8px;
  min-width: 390px;
  font-family: "Inter";
}
.BlueTxt{
  color: #059af4;
  font-weight: 600;
}
.SpendingContainer {
  position: relative;
}
.text-left p{
  font-family: "Inter";
}
.text-right p{
  font-family: "Inter";
}
.navigationBtn {
  position: absolute;
  right: 1.5%;
  top: 98px;
  min-width: 25px;
  cursor: pointer;
  filter: saturate(0%);
  opacity: 0.6;
  transform: rotate(180deg);
}
.leftPadding {
  padding-left: 500px !important;
}
.prevNavigationBtn {
  position: absolute;
  left: 0.5%;
  top: 102px;
  min-width: 25px;
  cursor: pointer;
  z-index: 99;
  /* transform: rotate(180deg); */
  filter: saturate(0%);
  opacity: 0.6;
}
.overlaybutton-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.overlaybutton-container button {
  background-image: linear-gradient(
    to right,
    var(--white) 0%,
    var(--white) 100%
  ) !important;
  color: black !important;
  font-family: "Inter";
}
.prevNavigationBtn.active-img,
.navigationBtn.active-img {
  filter: saturate(100%) !important;
  opacity: 1 !important;
}
.navigationBtn img,
.prevNavigationBtn img {
  min-width: 20px;
  background: #fff;
}
.filters-container {
  padding: 5px;
  max-width: 80%;
  position: relative;
}
.filters-container > div {
  border: 1px solid #cccccc;
  height: 32px;
  min-width: 90px;
  font-size: 13px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  border-radius: 4px;
  margin: 0 8px;
  padding: 8px 12px;
  cursor: pointer;
  user-select: none;
  margin: 10px;
}
.filters-container img {
  width: 13px;
}
.filter-active {
  border: 1px solid var(--main-blue) !important;
  color: var(--solid-colors);
}
.topScrollBar {
  width: 100%;
  height: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}

#div1 {
  overflow: hidden;
}

.table-containers::-webkit-scrollbar
/* ,.topScrollBar::-webkit-scrollbar  */ {
  height: 6px !important;
}

.table-containers::-webkit-scrollbar-track
/* ,.topScrollBar::-webkit-scrollbar-track  */ {
  background: #f3f4f8 !important;
}

.table-containers::-webkit-scrollbar-thumb
/* ,
.topScrollBar::-webkit-scrollbar-thumb  */ {
  width: 154px !important;
  max-height: 6px !important;
  border-radius: 30px !important;
  background-color: #666666 !important;
}

.tbl-column {
  width: fit-content;
}
.table-containers > div:nth-of-type(2) {
  border-right: 3px solid white;
}
.tbl-header-column {
  background-color: white !important;
}
.tbl-input {
  box-shadow: none !important;
  width: 80px !important;
}
.header-cell,
.placeholder-cell {
  height: 64px;
  background-color: white !important;
}
.placeholder-cell {
  width: 250px !important;
}
.tbl-cell {
  font-size: 13px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tbl-column > div {
  width: 150px;
  height: 64px;
  text-align: right;
  color: #000;
} 
.sidebar-cell {
  width: 250px !important;
  text-align: left !important;
  font-size: 14px;
  height: 64px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}
.balanceSheet {
  font-weight: 600 !important;
  background-color: var(--secondary-blue);
}
.cashOnHand-primary {
  background-color: var(--primary-blue);
}
.cashOnHand-secondary {
  background-color: var(--secondary-blue);
}
.liabilities-primary {
  background-color: var(--primary-yellow);

  font-size: 14px !important;
}
.liabilities-secondary {
  background-color: var(--white) cf0;
}
.monthlyCashFlow {
  background-color: #ebf2fa;
  font-weight: 600;
}
.income-primary {
  background-color: var(--primary-green);

  color: var(--text-color) !important;
  font-size: 14px !important;
}
.income-secondary {
  background-color: var(--secondary-green);
}
.expense-primary {
  background-color: var(--primary-red);

  color: var(--text-color) !important;
  font-size: 14px !important;
}
.expense-secondary {
  background-color: var(--secondary-red);
}
.EditableColumnTitle {
  background: white;
  height: 36px;
  width: 240px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: var(--grey-dark);
}

.net-personal-icome-saving {
  border-radius: 8px;
  height: 37px;
  width: max-content;
}
.net-personal-icome-saving > div {
  height: 37px !important;
  padding: 12px 8px;
  padding-top: 5px;
  width: max-content;
}
.net-personal-icome-saving p {
  color: var(--solid-colors);
  font-size: 16px;
  font-weight: bold;
}
.net-personal-icome-saving-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.table-title {
  position: absolute;
  left: 285px;
  background-color: white !important;
}
.table-title-current {
  position: absolute;
  left: 500px;
  background-color: white !important;
}
.ExtraCashTable {
  border-collapse: collapse;
}
.ExtraCashTable .p-inputnumber-input:focus,
.ExtraCashTable .credit-card-name:focus,
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: none;
  outline: none;
}
.ExtraCashTable .p-dropdown {
  width: 110px !important ;
}
.ExtraCashTable .p-dropdown-trigger {
  width: 20px;
}
.ExtraCashTable
  .p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down::before {
  color: #999999;

  margin-bottom: 15px;
  font-weight: normal;
}
.credit-card-name {
  width: 200px !important;
  height: 36px !important;
  font-size: 12px !important;
}
@media (max-width: 1430px) {
  .credit-card-name {
    width: 100px !important;
  }
}
.ExtraCashTable .p-inputnumber-input {
  border: none !important;
  outline: none !important;
}

.ExtraCashTable .extraCash-input-field-calender {
  width: 130px !important;
}
.ExtraCashTable .ExtraCashActionTD {
  width: 20px !important;
}
.ExtraCashTable .extraCash-input-field {
  width: 100px !important;
}
.ExtraCashTable1 .credit-input-field {
  width: 100px !important;
}
.EquityGainsTable {
  margin-top: -14px;
}
.EquityGains .p-inputnumber-input:focus,
.EquityGainsTable .EquityGains-name:focus,
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: none;
  outline: none;
}
.EquityGainsTable .p-dropdown {
  width: 110px !important ;
}
.EquityGainsTable .p-dropdown-trigger {
  width: 20px;
}
.EquityGainsTable
  .p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down::before {
  color: #999999;

  margin-bottom: 15px;
  font-weight: normal;
}
.EquityGains-name {
  width: 200px !important;
  height: 36px !important;
  font-size: 12px !important;
}
.EquityGainsTable .p-inputnumber-input {
  border: none !important;
  outline: none !important;
  width: 64px !important;
  border: none !important;
  outline: none !important;
}
.EquityGainsTable .EquityGains-field {
  width: 110px !important;
}
.collapseIcon {
  user-select: none;
  filter: invert();
  margin-left: -25px;
  margin-right: 5px;

  width: 18px;
  cursor: pointer;
}

.EquityGainsTable .EquityGains-fields {
  width: 64px !important;
}
.EquityGainsTable .p-inputtext:enabled:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.Pageoverlay {
  box-shadow: 0 0.2px 3.1px 0 rgb(0 0 0 / 0%), 0 1px 6.5px 0 rgb(0 0 0 / 1%),
    0 2.4px 13px 0 rgb(0 0 0 / 1%), 0 4.6px 25.5px 0 rgb(0 0 0 / 1%),
    0 7.8px 46.9px 0 rgb(0 0 0 / 2%), 0 3px 80px 0 rgb(0 0 0 / 1%);
  background-color: #323232;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  color: var(--white);
  font-family: "Inter";
  font-weight: bold;
  z-index: 100;
  text-align: center;
  opacity: 0.9;
  border-radius: 4px;
  transition: all 0.2s ease;
}
.Pageoverlay div {
  transition: all 0.2s ease;
}
.Contactlink {
  color: #059af4;
}
@media(max-width:770px){
  .itemValue{
    font-size: 12px !important;
  }
}
@media(max-width:613px){
  .itemValue{
    font-size: 10px !important;
  }
  .FloatingBox_itemTitle__3LaEW{
    font-size: 11px !important;
  }
}

