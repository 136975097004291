.container {
  width: 98%;
  margin: 20px auto;
}
.sub-title {
  font-size: 20px;
  font-weight: 500;
}
.input-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--blackColor) !important;
}
.slider-field {
  width: 204px;
  background-color: var(--primary-blue) !important;
}
.readonly-input-field {
  position: relative !important;
  top: -20px !important;
  height: 36px;
  max-width: 105px !important;
  color: var(--grey-dark) !important;
}
.readonly-input-field.w-83 {
  width: 83px !important;
  text-align: center !important;
}
.readonly-input-field.w-89 {
  width: 83px !important;
  text-align: center !important;
}
.readonly-input-field.w-67 {
  width: 67px !important;
  text-align: center !important;
}
.readonly-input-field.w-90 {
  width: 98px !important;
  text-align: center !important;
}