.Blurtxt{
    color: transparent !important;
    text-shadow: 0 0 8px #000;
    background-color: transparent;
    position: relative;
    user-select: none;
}
.Blurtxt:hover::after{
    content: "Premium Access Only";
  position: absolute;
  font-size: 13px;
  width: 150px;
  left: 0;
  top: 17px;
  border-radius: 15px;
  background-color: #272660;
  padding: 7px;
  color: white !important;
  text-shadow: none !important;
  opacity: 1 !important;
  z-index: 1000 !important;
}
.icometxt{
  position: relative;
}
.icometxt::after{
  content: "";
  position: absolute;
  top: 28px;
  width: 100%;
  border-radius: 12px;
  left: 0;
  background-color: #e0e0e0;
  height: 1px;
}