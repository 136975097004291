.tcenter {
  text-align: center !important;
}
.tleft {
  text-align: left !important;
}
.hueColor p span{
  font-weight: bold;
}
.sliders .p-slider-horizontal,
.sliders .p-inputtext {
  width: 14rem;
}

.sliders .p-slider-vertical {
  height: 14rem;
}

.w-60 {
  width: 70% !important;
  margin-bottom: 1rem !important;
}

p {
  margin-bottom: 0.1rem !important;
}

.boxs1 {
  padding-left: 0.5rem !important;
}
.boxs1 .col-fixed {
  border: 1px solid var(--white);
  background: var(--white);
  box-shadow: 0 1.5px 9.8px 0 rgba(11, 61, 126, 0.02),
    0 7.2px 38.2px 0 rgba(11, 61, 126, 0.02),
    0 18.7px 120px 0 rgba(7, 62, 132, 0.04);
  border-radius: 8.2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 22.5%;
  min-height: 150px;
}
.ToolTip-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ToolTip {
  position: relative;
}
.boxs1 .col-fixed1 {
  border: 1px solid var(--white);
  background: var(--white);
  box-shadow: 0 1.5px 9.8px 0 rgba(11, 61, 126, 0.02),
    0 7.2px 38.2px 0 rgba(11, 61, 126, 0.02),
    0 18.7px 120px 0 rgba(7, 62, 132, 0.04);
  border-radius: 8.2px;
  padding: 15px;
  min-width: 22.5%;
  min-height: 150px;
}

.icon {
  visibility: hidden;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
}
.col-fixed1:hover .icon {
  /* visibility: visible; */
}
.myboxes {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.myboxes .grid {
  /* gap: 43px !important; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}
.boxs1 .col-fixed p {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-blue);
  text-align: center;
}

.boxs1 .col-fixed1 p {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-blue);
  text-align: center;
}
.boxs1 .col-fixed p:first-child {
  margin: 0 0 12px;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0.8rem !important;
}
.Vector {
  width: 22.9px;
  height: 22.9px;
  flex-grow: 0;
  background-image: linear-gradient(45deg, var(--secondary-color-green) 12%);
}
.boxs1 .col-fixed1 p:nth-child(2) {
  margin: 0 0 12px;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0.8rem !important;
}
.table-container {
  width: 1300px;
  overflow: auto;
}
.tablwrapper{
  width: 1300px;
  overflow-y: auto
}
.remove-icon {
  cursor: pointer;
}
.tablwrapper ::-webkit-scrollbar{
  height: 8px !important;
  top:100px
}
.table-container::-webkit-scrollbar,
.topScrollBar::-webkit-scrollbar {
  height: 8px !important;
}

.table-container::-webkit-scrollbar-track,.tablwrapper::-webkit-scrollbar-track ,
.topScrollBar::-webkit-scrollbar-track {
  background: #f3f4f8 !important;
}

.table-container::-webkit-scrollbar-thumb,.tablwrapper::-webkit-scrollbar-thumb ,
.topScrollBar::-webkit-scrollbar-thumb {
  width: 124px !important;
  max-height: 8px !important;
  border-radius: 30px !important;
  background-color: #666666 !important;
}

.tblData {
  margin-bottom: 0;
  max-width: none;
  white-space: nowrap;
  background-color: var(--white);
  border: 0px solid var(--white);
}

.tblData tr {
  padding: 0 45px 0 19px;
  border-bottom: 1.5px solid #f3f4f8;
}
.tblData td {
  padding: 18px 40px 20px 0px;
}
.tblData tr td:first-child {
  padding: 35px 60px 10px 30px;
}

.tblData td:first-child span {
  position: relative;
  top: -12px;
}

.tblData tr:nth-child(4) td {
  vertical-align: bottom;
}

.tblData tr:nth-child(2) {
  background-color: #ebf2fa;
}
.tblData tr:nth-child(2) td {
  padding: 0px 30px 20px 0px;
}

.tblData
  tr:not(:nth-child(2), :nth-child(10), :nth-child(12))
  td:first-child {
  position: sticky;
  background-color: white;
  left: 0;
  z-index: 999;
}
.tblData
  tr:not(:nth-child(2), :nth-child(10), :nth-child(12))
  td:first-child {
  position: sticky;
  background-color: white;
  left: 0;
  z-index: 999;
}

.tblData tr:nth-child(2) td:first-child {
  padding: 25px 0 12px 30px;
  background-color: #ebf2fa;
  position: sticky;
  left: 0;
  z-index: 999;
}

.tblData tr:nth-child(6):hover .icon {
  margin-left: 20px;
  visibility: visible;
}
.tblData tr:nth-child(15):hover .icon {
  visibility: visible;
}

.tblData tr:nth-child(10) {
  background-color: #ffcccb;
  background-color: rgb(255, 172, 169);
}
.tblData tr:nth-child(12) {
  background-color: #ebf2fa;
}
.tblData tr:nth-child(17) {
  background-color: #ebf2fa;
}
.tblData tr:nth-child(23) {
  background-color: #ebf2fa;
}

.tblData tr:nth-child(10) td:first-child {
  background-color: #ffcccb;
  background-color: rgb(255, 172, 169);
  position: sticky;
  left: 0;
  z-index: 999;
}
.tblData tr:nth-child(12) td:first-child {
  background-color: #ebf2fa;
  position: sticky;
  left: 0;
  z-index: 999;
}
.tblData tr:nth-child(17) td:first-child {
  background-color: #ebf2fa;
  position: sticky;
  left: 0;
  z-index: 999;
}
.tblData tr:nth-child(23) td:first-child {
  background-color: #ebf2fa;
  position: sticky;
  left: 0;
  z-index: 999;
}

.tblData tr:last-child {
  border: none;
  background: #fbfcfc;
}

.tblData tr:last-child td {
  padding: 13px 4px 13px 0px;
}

.tblData tr:nth-child(21) td:last-child {
  width: 180px;
  color: var(--primary-green);
  font-weight: 500;
  background-image: url("../../assets/images/dart1.png");
  background-repeat: no-repeat;
  background-position: 92% 40%;
  background-size: 30%;
}
.tblData tr:nth-child(17) td:last-child::after {
  width: 22.9px;
  height: 22.9px;
  flex-grow: 0;
  background-color: var(--secondary-green);
}


.tblData tr td {
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
}
.tblData tr td:first-child {
  text-align: left;
}

.tblData tr:nth-child(1) td:nth-child(2) {
  background-color: white !important;
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}

.tblData tr:nth-child(11) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(11) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(1) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(15) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(15) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(22) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(22) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(25) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(25) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(26) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(26) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(16) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(16) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(3) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(3) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(5) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(5) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(6) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(6) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(7) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(7) td:nth-child(2) > p {
  opacity: 1;
}
.tblData tr:nth-child(8) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData tr:nth-child(8) td:nth-child(2) > p {
  opacity: 1;
}



.tblData1 {
  margin-bottom: 0;
  max-width: none;
  white-space: nowrap;
  background-color: var(--white);
  border: 0px solid var(--white);
}

.tblData1 tr {
  padding: 0 45px 0 19px;
  border-bottom: 1.5px solid #f3f4f8;
}
.tblData1 td {
  padding: 18px 10px 20px 0px;
}
.tblData1 tr td:first-child {
  padding: 35px 60px 10px 30px;
}

.tblData1 td:first-child span {
  position: relative;
  top: -12px;
}

.tblData1 tr:nth-child(4) td {
  vertical-align: bottom;
}

.tblData1 tr:nth-child(2) {
  background-color: #ebf2fa;
}
.tblData1 tr:nth-child(2) td {
  padding: 0px 30px 20px 0px;
}

.tblData1
  tr:not(:nth-child(2), :nth-child(10), :nth-child(12))
  td:first-child {
  position: sticky;
  background-color: white;
  left: 0;
  z-index: 999;
}
.tblData1
  tr:not(:nth-child(2), :nth-child(10), :nth-child(12))
  td:first-child {
  position: sticky;
  background-color: white;
  left: 0;
  z-index: 999;
}

.tblData1 tr:nth-child(2) td:first-child {
  padding: 25px 0 12px 30px;
  background-color: #ebf2fa;
  position: sticky;
  left: 0;
  z-index: 999;
}

.tblData1 tr:nth-child(6):hover .icon {
  margin-left: 20px;
  visibility: visible;
}
.tblData1 tr:nth-child(15):hover .icon {
  visibility: visible;
}

.tblData1 tr:nth-child(10) {
  background-color: #ffcccb;
  background-color: rgb(255, 172, 169);
}
.tblData1 tr:nth-child(12) {
  background-color: #ebf2fa;
}
.tblData1 tr:nth-child(17) {
  background-color: #ebf2fa;
}
.tblData1 tr:nth-child(23) {
  background-color: #ebf2fa;
}

.tblData1 tr:nth-child(10) td:first-child {
  background-color: #ffcccb;
  background-color: rgb(255, 172, 169);
  position: sticky;
  left: 0;
  z-index: 999;
}
.tblData1 tr:nth-child(12) td:first-child {
  background-color: #ebf2fa;
  position: sticky;
  left: 0;
  z-index: 999;
}
.tblData1 tr:nth-child(17) td:first-child {
  background-color: #ebf2fa;
  position: sticky;
  left: 0;
  z-index: 999;
}
.tblData1 tr:nth-child(23) td:first-child {
  background-color: #ebf2fa;
  position: sticky;
  left: 0;
  z-index: 999;
}

.tblData1 tr:last-child {
  border: none;
  background: #fbfcfc;
}

.tblData1 tr:last-child td {
  padding: 13px 4px 13px 0px;
}

.tblData1 tr:nth-child(20) td:last-child {
  width: 180px;
  color: var(--primary-green);
  font-weight: 500;
  background-image: url("../../assets/images/dart1.png");
  background-repeat: no-repeat;
  background-position: 92% 40%;
  background-size: 30%;
}
.tblData1 tr:nth-child(17) td:last-child::after {
  width: 22.9px;
  height: 22.9px;
  flex-grow: 0;
  background-color: var(--secondary-green);
}


.tblData1 tr td {
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
}
.tblData1 tr td:first-child {
  text-align: left;
}

.tblData1 tr:nth-child(1) td:nth-child(2) {
  background-color: white !important;
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}

.tblData1 tr:nth-child(11) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(11) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(1) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(15) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(15) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(22) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(22) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(25) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(25) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(26) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(26) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(16) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(16) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(3) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(3) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(5) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(5) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(6) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(6) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(7) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(7) td:nth-child(2) > p {
  opacity: 1;
}
.tblData1 tr:nth-child(8) td:nth-child(2) {
  /* background-color: white !important; */
  color: black !important;
  text-shadow: none !important;
  opacity: 1;
}
.tblData1 tr:nth-child(8) td:nth-child(2) > p {
  opacity: 1;
}




.propertyhead {
  font-family: Inter;
  color: black;

  font-size: 14px;
  font-weight: 500;
}
.blur {
  opacity: 0.02;
  background-color: black;
  color: whitesmoke;
  text-shadow: 0 0 8px #000;
}
.blur1 {
  text-shadow: 0 0 8px #000;
  user-select: none;
}

.fixedRow {
  position: fixed;
  top: 0px;
}
.tcolor {
  color: #e0e0e0 !important;
}
.bgGolden{
  background-color: #FFD700 !important;
}
.tbackground {
  opacity: 0.9;
  height: 35px;
  /* margin-left: 30px; */

  z-index: 100;
  /* border-color: #cccccc !important; */
  border-top: none;
  border-bottom: none;
  /* border-radius: 0px; */
  border-bottom-color: #f3f4f8 !important ;
}

.blacktxt { 
  /* font-weight: bold; */
  color: black !important;
}
.blacktxt p span {
  /* font-weight: bold; */
  /* color: black !important; */
}
.tbackground td:not(.blacktxt),
span.tbackground {
  color: transparent;
  text-shadow: 0 0 8px #000;
  user-select: none;
  position: relative;
}
.tbackground td:not(.blacktxt) > p {
  opacity: 0.4;
}

.col-fixed1 .blur1:hover .premiumaccess {
  visibility: visible;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 13px;
  top: -15px;
  border-radius: 15px;
  background-color: #272660;
  padding: 7px;
  color: white !important;
  text-shadow: none !important;
  /* opacity: 1 !important; */
  z-index: 1000 !important;
}
.col-fixed1 .blur1 .premiumaccess {
  visibility: hidden;
}

.tbackground:hover td:nth-child(4)::after {
  content: "Premium Access Only. Please contact info@investlogic.com.au to unlock these features";
  position: absolute;
  font-size: 13px;
  left: -100%;
  top: 17px;
  border-radius: 15px;
  background-color: #272660;
  padding: 7px;
  color: white !important;
  text-shadow: none !important;
  opacity: 1 !important;
  z-index: 1000 !important;
}
.bgwhite {
  background-color: white !important;
  color: black !important;
}
/* .tblData tr:nth-child(1) td:nth-child(2) p span{
  background-color: white !important;
  color: black !important;
} */

.dNone {
  display: none;
}
.BlurYear {
  color: transparent !important;
  text-shadow: 0 0 13px rgb(83, 83, 83);
  background-color: transparent;
  position: relative;
  user-select: none;
}
.prevNavigation.active,
.navigation.active {
  filter: saturate(100%) !important;
  opacity: 1 !important;
}
.done-edit {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  align-self: start;
  padding: 8px 0px;
  cursor: pointer;
  bottom: 25px;
  color: var(--main-blue);
}

.navigation1 {
  position: absolute;
  right: -23.5%;
  top: 55px;
  min-width: 25px;
  cursor: pointer;
  filter: saturate(0%);
  opacity: 0.6;
  transform: rotate(180deg);
  z-index: 2000;
}
.prevNavigation1 {
  position: absolute;
  left: 0%;
  top: 55px;
  min-width: 25px;
  cursor: pointer;
  z-index: 2000;
  filter: saturate(0%);
  opacity: 0.6;
}

.filter-container {
  padding: 5px;
  max-width: 80%;
  position: relative;
}
.filter-container > div {
  border: 1px solid #cccccc;
  height: 32px;
  min-width: 90px;
  font-size: 13px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  border-radius: 4px;
  margin: 0 8px;
  padding: 8px 12px;
  cursor: pointer;
  user-select: none;
  margin: 10px;
}
.filter-container img {
  width: 18px;
}

.tablet {
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid white;
  box-shadow: 5px #000;
  padding: 15px 34px;
  font-size: 13px;
  text-align: center;
}
.tabletbtn {
  background: none !important;
  border: none !important;
  padding: 0px !important;
  box-shadow: none !important; 
}
.tablet-btn {
  position: relative;
  top: -32px;
  left: 52px;
  color: var(--secondary-green);

  border: none;
}
.tablet-btn:hover {
  color: var(--secondary-green);
  background-color: var(--main-blue);
  border: none;
}
.tablet-5 {
  color: #00a779;
  background-color: #ebfff9;
}
.tablet-6 {
  color: #fee468;
  background-color: #fffcf0;
}
.tablet-7 {
  color: #cd4a5e;
  background-color: #fef3f5;
}
.datepill {
  font-size: 13px;
  font-weight: 600;
  color: var(--solid-colors);
  border-radius: 5px;

  width: 100px;
  text-align: center;
}

.propertypill {
  padding: 9px 0px;
  white-space: nowrap;
  width: 100px;
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 5px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.Property-head {
  transition: none !important;
}
.Property-head:hover {
  color: var(--main-blue);
}
.hueColor{
  background-color:#cefad0;
  /* width:15%; */
}
@media only screen and (max-width: 4390px) {
  /* .myboxes .grid {
    gap: 69.5px !important;
    margin-top: 50.5px;
  } */
  .table-container {
    width: 3350px;
  }
}

@media only screen and (max-width: 3500px) {
  /* .myboxes .grid {
    gap: 69.5px !important;
    margin-top: 50.5px;
  } */
  .table-container {
    width: 2600px;
  }
}
@media only screen and (max-width: 2625px) {
  /* .myboxes .grid {
    gap: 69.5px !important;
    margin-top: 50.5px;
  } */
  .table-container {
    width: 2190px;
  }
}
@media only screen and (max-width: 2225px) {
  /* .myboxes .grid {
    gap: 58px !important;
    margin-top: 40.5px;
  } */
  .table-container {
    width: 1740px;
  }
}
@media only screen and (max-width: 2200px) {
  /* .myboxes .grid {
    gap: 53px !important;
    margin-top: 38.5px;
  } */
  .table-container {
    width: 1860px;
  }
}
@media only screen and (max-width: 2150px) {
  /* .myboxes .grid {
    gap: 53px !important;
    margin-top: 38.5px;
  } */
  .table-container {
    width: 1620px;
  }
}

@media only screen and (max-width: 1925px) {
  /* .myboxes .grid {
    gap: 50.5px !important;
    margin-top: 45.5px;
  } */
  .table-container {
    width: 1580px;
  }
}

@media only screen and (max-width: 1850px) {
  /* .myboxes .grid {
    gap: 47px !important;
    margin-top: 42px;
  } */
  .table-container {
    width: 1500px;
  }
}

@media only screen and (max-width: 1760px) {
  /* .myboxes .grid {
    gap: 46px !important;
    margin-top: 41px;
  } */
  .table-container {
    width: 1450px;
  }
}

@media only screen and (max-width: 1730px) {
  /* .myboxes .grid {
    gap: 43px !important;
    margin-top: 38px;
  } */
  .table-container {
    width: 1360px;
  }
}

@media only screen and (max-width: 1680px) {
  .table-container {
    width: 1275px;
  }
}

@media only screen and (max-width: 1600px) {
  /* .myboxes .grid {
    gap: 40px !important;
    margin-top: 35px;
  } */
  .table-container {
    width: 1250px;
  }
}

@media only screen and (max-width: 1480px) {
  /* .myboxes .grid {
    gap: 47px !important;
    margin-top: 42px;
  } */
  .table-container {
    width: 1000px;
  }
}

@media only screen and (max-width: 1445px) {
  /* .myboxes .grid {
    gap: 30px !important;
    margin-top: 25px;
  } */
  .table-container {
    width: 960px;
  }
}

@media only screen and (max-width: 1265px) {
  /* .myboxes .grid {
    gap: 26px !important;
    margin-top: 25px;
  } */
  .table-container {
    width: 900px;
  }
}

@media only screen and (max-width: 1200px) {
  /* .myboxes .grid {
    gap: 22px !important;
    margin-top: 21px;
  } */
  .table-container {
    width: 850px;
  }
}

@media only screen and (max-width: 1155px) {
  /* .myboxes .grid {
    gap: 20px !important;
    margin-top: 20px;
  } */
  .table-container {
    width: 800px;
  }
}

@media only screen and (max-width: 1105px) {
  /* .myboxes .grid {
    gap: 18px !important;
    margin-top: 17px;
  } */
  .table-container {
    width: 700px;
  }
}

@media only screen and (max-width: 1005px) {
  /* .myboxes .grid {
    gap: 17px !important;
    margin-top: 16px;
  } */
  .sumaryboxes{
    margin-top: 48px !important;
  }
  .table-container {
    width: 100%;
  }
}
@media(max-width:860px){
  .sumaryboxes{
    margin-top: 36px !important;
  }
}
@media(max-width:785px){
  .sumaryboxes{
    margin-top: 50px !important;
  }
}
.boxShow{
  display: none !important;
}
.purchase-txt{
  margin-top: -15px !important;
}
@media(max-width:680px){
  .myboxes .grid{
    justify-content: flex-start;
  }
}
@media(max-width:650px){
  .sumaryboxes{
    margin-top: 30px !important;
  }
}
@media(max-width:590px){
  .sumaryboxes{
    margin-top: -10px !important;
  }
}
@media(max-width:475px){
  .sumaryboxes{
    margin-top: -10px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .boxHide{
    display: none !important;
  }
  .boxShow{
    display: block !important;
    padding-top: 36px
  }
  .purchase-txt{
    margin-top: -12px !important;
  }
  /* .myboxes .grid {
    gap: 17px !important;
    margin-top: 16px;
  } */
  /* .table-container {
    width: 400px;
  } */
}
