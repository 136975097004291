.EmailInput:focus , .PasswordInput:focus{
    border-color: none !important;
    box-shadow: none !important;
    outline: 0 none !important;
}
.EmailInput , .PasswordInput{
    border-radius: 4px !important;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
}

.EmailInput{
    border: 1.2px solid #666666 !important;
}
.PasswordInput{
    border:none !important;
}
.PasswordInputGroup{
    border: 1.2px solid #666666 !important;
    border-radius: 4px !important;
}
.passwordShowHide{
    border: none !important;
    background-color: white !important;
    font-size: 13px;
    font-weight: 500;
    color: var(--grey-dark);
}
.showHideBtn{
    cursor: pointer;
    user-select: none;
}
.EmailInput::placeholder, .PasswordInput::placeholder{
    color: #999999 !important;
}
.hiddenMsg{
    color: red;
    padding-bottom: 5px !important;
    font-size: 14px;
}
.CheckboxInput{
    margin-top: 6px !important;
    border:1.2px solid #666666 !important;
}
.CheckboxInput:checked{
    background-color: var(--heading-color) !important;
}
.CheckboxLabel{
    font-weight: 400;
    font-size: 13px;
    color: #666666;
}


.createAccountLink{
    font-weight: 400;
    font-size: 16px;
    color: #999999;
    text-align: center;
    padding-top:25px;
}
.createAccountLink a{
    color:var(--main-blue);
    text-decoration: none;
}
.link{
    font-size: 14px;
    color: var(--heading-color);
    cursor: pointer;
    margin-top: 10px;
    line-height: 0;
   
}
.passBox{
    width:20px;
    background:#62c68a;
    height:20px;
    border-radius:50px;
    display:flex;
    justify-content:center;
    align-items:center;
    
}
.wrongBox{
    width:20px;
    background:red;
    height:20px;
    border-radius:50px;
    display:flex;
    justify-content:center;
    align-items:center;
    
}
.icon{
    color:white;
    font-size: 10px !important;
}