 .mycard {
    width: 98%;
    margin: 20px auto;
    
}
.w100 {
    width: 100% !important;
}
.p-datatable {
    width: 100% !important;
    border: 3px solid #f8f9fa !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 0.5rem;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.5rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

 

.pi {
    font-size: 12px !important;
   
    cursor: pointer;
}
.save-icon{
    font-size:2rem !important;
    color:rgb(8, 216, 8)
  }
.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 1.5rem;
}

.p-button.p-button-icon-only {
    width: 1.5rem;
    padding: 0.5rem 0;
}


.customtabs .p-tabview-nav-container {
    margin-left: 0px;
}

.customtabs .p-tabview-nav-link {
    padding: 0.5rem 0 0.5rem 0 !important;
    margin: 0px !important;
}

.customtabs .p-unselectable-text{
    margin-right: 1rem !important;
}  
.customtabs .p-tabview-nav {
    min-height: 70px;
    justify-content: initial;
    border-radius: initial;
    border: initial !important;
    position: relative;
} 
.customtabs .p-tabview-selected,.customtabs .p-highlight {
    border-bottom: 2px solid #272660 !important;
}

.customtabs .p-tabview-selected .p-tabview-title {
    font-weight: 600;
} 
@media(max-width:500px){
    .customtabs{
        padding-left: 5px !important;
    }
}