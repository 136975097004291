.animated-height {
    overflow: hidden;
    transition: all 0.3s ease-in-out; /* Adjust the duration and easing function as needed */
    
  }
  
  .section {
    overflow:hidden;
    transition:all 0.5s ease;
    height:auto;
    max-height: 0;
    
  }
  .section.open{
    
    max-height:2000px;
  }
  .section.close{
    max-height:0px;
  }
  
  
  
  