.PinderPageContainer {
  padding-left: 16px;
  padding-right: 24px;
}
.pageTitle {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  color: #323232;
}
.filterDropdown {
  width: 100%;
  height: 32px;

}

.FilterContainer{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 60vw;
  
}
.ClearFilterButton{
  display: inline-block !important;

  gap: 2px !important;
  background-color: white !important;
  width: auto;
  height: auto;
  padding: 8px 10px !important;
  border-color: #cccccc !important;
  color: #262660 !important;
  font-family: "Inter" !important;
  font-size: 12px !important;
  border-radius: 2px !important;
}
.filterheader{
  display: flex;
  justify-content: space-between;
  
}
.filterheader p{
  font-family: "Inter";
  font-size: 14px;
  font-weight: bold;
}
.filterheader span{
  font-family: "Inter";
  font-size: 11px;
  color:#0465eb
}
 .filtersort{
  margin-top: 10px;
  display: flex;
  justify-content: space-between; 
  align-items: center;
}
.filtersort p{
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap:4px
}
.prevNavigationBtn2 {
  position: absolute;
  top: 14px; 
  left: -7px;
  z-index: 1000;
  cursor: pointer;
  z-index: 2000;
  transform: rotate(180deg);
  filter: saturate(0%);
  opacity: 0.8;
}
.activeImg,
.activeImg {
  filter: saturate(100%) !important;
  opacity: 1 !important;
}
.prevNavigationBtnImg {
  width: 26px;
}
.navigationBtn2 {
  position: absolute;
  right: -7px;
  top: 14px;
  z-index: 1000;
  cursor: pointer;
}

.navigationBtnImg,

.firstColumn {
  padding-left: 40px !important;
}
.deleteReport{
  width: 140px !important;
  height: 36px !important;
  border-color: #cccccc !important; 
  color: #262660 !important;
  font-family: "Inter" !important;
  display: flex;
  align-items: center;
}
.deleteReport span{
  margin-top: 2px !important;
}

/* ------------- */

