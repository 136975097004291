.p-datatable-wrapper::-webkit-scrollbar{
    width: 10px;
  }
  .p-datatable-wrapper::-webkit-scrollbar-track{
    background: #f3f4f8 !important;
  }
  .p-datatable-wrapper::-webkit-scrollbar-thumb{
    min-height: 20px !important;
    border-radius: 30px !important;
    background-color: #666666 !important;
  }
  .p-multiselect-item{ 
    font-size: 13px !important;
  }
  .result{
    font-family: "Inter";
    font-size: 16px !important;
    color: #323232;
  }
  .rowbackground td{ 
    background-color: #93beee !important;
  }
  .column-hover-info {
    position: absolute;
    /* display: none; */
    background-color: orange;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 9999;
    width: 300px;
    height: auto;
    
  }
  .p-multiselect-panel .p-multiselect-items-wrapper{
    overflow-x: hidden !important;
  }
  .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item span{
    overflow-x: hidden !important;
  }
 