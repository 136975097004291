.aside {
  transition: 0.6s all ease;
  width: 20%;
  height: 100%;
  margin: 20px 32px 43px 16px;
  padding: 10px 16px 17px;
  border-radius: 8px;
  background-image: linear-gradient(
    10deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 123%
  );
  color: #fff;
  font-family: "Inter";
  position: -webkit-sticky; 
  position: sticky;
  top: 23vh;
  margin-right: 2px;
 
}
.heading {
  font-size: 18px;
  font-weight: bold;
}
.itemTitle {
  font-size: 12px;
  font-weight: normal;
}
.itemValue {
  font-size: 16px;
  font-weight: 600;
}
.ResultBox {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  background-color: #fff;
  padding: 7px;
  color: var(--heading-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fadeOut {
  animation-name: FadeOut;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.floatingbox{
display: block;
}
@media(max-width:1200px){
  .floatingbox{
    display: none;
  }
}
@keyframes FadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
