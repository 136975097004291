.InfoBox {
  width: 250px;
  height: auto;
  padding: 5px 10px;
  border-radius: 6px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  box-shadow: 0 0.4px 2.7px 0 rgba(0, 0, 0, 0.01),
    0 1.5px 5.6px 0 rgba(0, 0, 0, 0.01), 0 3.8px 11.2px 0 rgba(0, 0, 0, 0.01),
    0 7.3px 22px 0 rgba(0, 0, 0, 0.02), 0 12.3px 40.4px 0 rgba(0, 0, 0, 0.02),
    0 19px 69px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  position: absolute;
  right: 200px;
  z-index: 10000;
  text-align: left;
}
.InfoBox p {
  color: #323232 !important;
}
.InfoBox ul{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
}
