.mainContainer{
    min-height: 100vh;
    background-color: var(--white);
}
.vectorInfo{
    background: linear-gradient(225deg, var(--gradient-blue-3) 0%, var(--gradient-blue-4) 0.01%, var(--gradient-blue-5) 100%);
}
.logoContainer{
    width: 90%;
    margin:10px auto;
}
.logo{
    width: 180px;
}
.vectorImgContainer{
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vectorImg{
    width: 85%;
}
.formContainer{
    min-height: 100vh;
    width: 65%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.greeting{
    color: var(--heading-color);
    font-weight: 600;
    font-size: 26px;

}
.title{
    color:var(--grey-dark);
    font-weight: 500;
    font-size: 24px;
}
.link{
    font-size: 14px;
    color: var(--heading-color);
    cursor: pointer;
    margin-top: 10px;
    line-height: 0;
}
.linkSignup{
    font-size: 14px;
    color: var(--heading-color);
    cursor: pointer;
    margin-left: 5px;
}